<template>
	<div class="box box_mm">
		<div class="box_main">
			<div class="search_box">
				<div class="search_item">
					<span style="width: 0.8rem;">文件标题：</span>
					<el-input v-model="searchinput" placeholder="请输入" style="width: 2rem;"></el-input>
				</div>
				<div class="search_item">
					<el-button type="primary" @click="search">搜索</el-button>
					<!-- <el-button type="success" @click="addnew">新建公文处理单</el-button> -->
				</div>
			</div>
			<div class="box_cont">
				<div class="btn_list">
				</div>
				<div style="height: 76vh;box-sizing: border-box;">
					<MyTable ref="zjlist" :tableData="tableData" :tableHeader="tableHeader" :showbm="true"
						:czwidth="280" @getdata="gettable" @changswitch="changswitch">
						<template slot="btns" slot-scope="{ scope }">
							<el-button @click="showdetail(scope.row)" type="primary" size="mini">详情</el-button>
							<el-button @click="postdata(scope.row)" type="success" size="mini">提交复核</el-button>
							<el-button @click="postdata2(scope.row)" type="success" size="mini">提交签发</el-button>
						</template>
					</MyTable>
				</div>
			</div>
		</div>
		
		<!--复核弹出-->
		<el-dialog title="提交复核" :visible.sync="showtc" width="40%" :before-close="setsheng">
			<div>
				<el-form ref="ruleForm" class="demo-ruleForm">
					  <el-form-item :label="selectrow.type==3||selectrow.type==2?'选择办公室主任复核人':selectrow.type==4?'选择副秘书长复核人':'选择秘书长复核人'" required>
					  		<div style="display: flex;align-items: center;">
					  			<el-button type="primary" size="small" @click="select(1)">选择</el-button>								
					  		</div>
					  		<div>
					  			<span style="color: #999;">已选人员：</span>{{user_name}}
					  		</div>
					  </el-form-item>
					  <!-- <el-form-item label="选择交办人" required>
					  		<div style="display: flex;align-items: center;">
					  			<el-button type="primary" size="small" @click="select(3)">选择</el-button>								
					  		</div>
					  		<div>
					  			<span style="color: #999;">已选人员：</span>{{user_name3}}
					  		</div>
					  </el-form-item> -->
				</el-form>
			</div>
			<span slot="footer" class="dialog-footer">
				<el-button @click="setsheng">取 消</el-button>
				<el-button type="success" @click="queren">提交复核</el-button>
			</span>
		</el-dialog>
		<!--签发弹出-->
		<el-dialog title="提交签发" :visible.sync="showtc2" width="40%" :before-close="setsheng">
			<div>
				<el-form ref="ruleForm" class="demo-ruleForm">
					  <el-form-item label="选择签发人" required>
					  		<div style="display: flex;align-items: center;">
					  			<el-button type="primary" size="small" @click="select(2)">选择</el-button>								
					  		</div>
					  		<div>
					  			<span style="color: #999;">已选人员：</span>{{user_name2}}
					  		</div>
					  </el-form-item>
					 <!-- <el-form-item label="选择交办人" required>
					  		<div style="display: flex;align-items: center;">
					  			<el-button type="primary" size="small" @click="select(3)">选择</el-button>								
					  		</div>
					  		<div>
					  			<span style="color: #999;">已选人员：</span>{{user_name3}}
					  		</div>
					  </el-form-item> -->
				</el-form>
			</div>
			<span slot="footer" class="dialog-footer">
				<el-button @click="setsheng">取 消</el-button>
				<el-button type="success" @click="queren2">提交签发</el-button>
			</span>
		</el-dialog>
		
		
		<detail ref="mydetail"></detail>
		<selectuser ref="myselectuser" @selectedry="selectedry"></selectuser>
	</div>
</template>

<script>
	import detail from '../components/detail.vue'
	import selectuser from '@/components/select_user.vue'
	export default {
		components:{ detail,selectuser },
		data() {
			return {
				searchinput: '',
				tableHeader: [{
						prop: 'id',
						name: '序',
						width: '50'
					},
					{
						prop: 'name',
						name: '文件标题',
						width: ''
					},
					{
						prop: 'fhuser_name',
						name: '复核人',
						width: '120'
					},
					{
						prop: 'qfuser_name',
						name: '签发人',
						width: '120'						
					},
					{
						prop: 'jjcd',
						name: '加急程度',
						width: '120',
						type:'jjcd'
					},
					{
						prop: 'addtime',
						name: '提交时间',
						width: '150'
					}
				],
				tableData: [],
				selectrow:'',//操作的数据
				showtc:false,
				showtc2:false,
				sheng:{
					id:'',
					fhuser_id:'',//复核人
					qfuser_id:'',//签发人
					zsuser_id:''//交办人
				},
				selet_type:0,
				user_name:'',
				user_id:'',//复核人
				user_name2:'',
				user_id2:'',//签发人
				user_name3:'',
				user_id3:''//交办人
			}
		},
		mounted() {
			this.gettable()
		},
		methods: {
			queren2(){
				if(!this.sheng.qfuser_id){
					this.$message.warning('请选择签发人')
					return
				}
				// if(!this.sheng.zsuser_id){
				// 	this.$message.warning('请选择交办人')
				// 	return
				// }
				this.$post({
					url: '/api/matterfwen/tjqianfa',
					params: this.sheng
				}).then((res) => {
					this.$message({
						type: 'success',
						message: '操作成功!'
					})
					this.gettable()
					this.setsheng()
				})
			},
			queren(){
				if(!this.sheng.fhuser_id){
					this.$message.warning('请选择复核人')
					return
				}
				
				this.$post({
					url: '/api/matterfwen/tjfuhe',
					params: this.sheng
				}).then((res) => {
					this.$message({
						type: 'success',
						message: '操作成功!'
					})
					this.gettable()
					this.setsheng()
				})
			},
			setsheng(){
				this.sheng={
					id:'',
					fhuser_id:'',//复核人
					qfuser_id:'',//签发人
					zsuser_id:''//交办人
				}
				this.user_name = ''
				this.user_id = ''//复核人
				this.user_name2 = ''
				this.user_id2 = ''//签发人
				this.user_name3 = ''
				this.user_id3 = ''//交办人
				this.showtc = false
				this.showtc2 = false
			},
			select(index){
				this.selet_type = index
				let cxidlist = []
				let cxnamelist = []
				if(index==1){//复核人
					cxidlist = this.user_id?this.user_id.split(','):[]
					cxnamelist = this.user_name?this.user_name.split(','):[]
					this.$refs.myselectuser.isdanxuan = true
				}
				if(index==2){//签发人
					this.$refs.myselectuser.isdanxuan = false
					cxidlist = this.user_id2?this.user_id2.split(','):[]
					cxnamelist = this.user_name2?this.user_name2.split(','):[]
				}	
				if(index==3){//选择交办人
					this.$refs.myselectuser.isdanxuan = false
					cxidlist = this.user_id3?this.user_id3.split(','):[]
					cxnamelist = this.user_name3?this.user_name3.split(','):[]
				}	
				let list = []
				if(cxidlist.length>0){
					cxidlist.forEach((item,index)=>{
						let obj={
							name:cxnamelist[index],
							id:Number(item)
						}
						list.push(obj)
					})
				}	
				this.$refs.myselectuser.selectuser = list.length>0?list:[]
				this.$refs.myselectuser.dialogVisible = true
			},
			selectedry(e){
				if(this.selet_type==1){
					this.user_id = e.map((item)=>{ return item.id })
					this.user_id = this.user_id.join(',')
					this.user_name = e.map((item)=>{ return item.name })
					this.user_name = this.user_name.join(',')
					
					this.sheng.fhuser_id = this.user_id
				}
				if(this.selet_type==2){
					this.user_id2 = e.map((item)=>{ return item.id })
					this.user_id2 = this.user_id2.join(',')
					this.user_name2 = e.map((item)=>{ return item.name })
					this.user_name2 = this.user_name2.join(',')
					
					this.sheng.qfuser_id = this.user_id2
				}
				if(this.selet_type==3){
					this.user_id3 = e.map((item)=>{ return item.id })
					this.user_id3 = this.user_id3.join(',')
					this.user_name3 = e.map((item)=>{ return item.name })
					this.user_name3 = this.user_name3.join(',')
					
					this.sheng.zsuser_id = this.user_id3
				}
			},
			showdetail(row){
				this.$post({
					url: '/api/matterfwen/details',
					params: {
						id:row.id
					}
				}).then((res) => {
					this.$refs.mydetail.info = res
					this.$refs.mydetail.dialogVisible = true
				})
			},
			postdata(row) {
				this.selectrow = row
				this.showtc = true
				this.sheng.id = row.id
			},
			postdata2(row) {
				this.showtc2 = true
				this.sheng.id = row.id
			},
			showeidt(row) {
				this.$router.push('/system/get_file/edit?id=' + row.id)
			},
			changswitch(row) {
				this.$post({
					url: '/api/room/edit',
					params: row
				}).then((res) => {
					this.$message({
						type: 'success',
						message: '操作成功!'
					})
					this.gettable()
				})
			},
			search() {
				this.$refs.zjlist.inputpage = 1
				this.gettable()
			},
			gettable() {
				this.$post({
					url: '/api/matterfwen/index',
					params: {
						name: this.searchinput,
						p: this.$refs.zjlist.inputpage,
						size: this.$refs.zjlist.pagesize,
						type: 2
					}
				}).then((res) => {
					this.tableData = res.list
					this.$refs.zjlist.total = res.count
				})
			},
			addnew() {
				this.$router.push('/system/get_file/add')
			},
			showdate(row) {
				this.$refs.myroomdate.dialogVisible = true
			},
		}
	}
</script>

<style lang="scss" scoped>
	@import 'shenghe_pass.scss';
</style>